// require('dotenv').config();

/**
 * API Server URL
 *
 * @type {string}
 */
exports.API_URL = process.env.REACT_APP_API_URL || "";

/**
 * Application URL
 *
 * @type {string}
 */
exports.APP_URL = process.env.REACT_APP_APP_URL || "";

/**
 * Mobile URL
 *
 * @type {string}
 */
exports.MOBILE_URL = process.env.REACT_APP_MOBILE_URL || "";
